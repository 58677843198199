@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;
}

.createListingLink:hover {
  color: var(--marketplaceColorDark);
  border: none;
}

.createListing {
  @apply --TopbarDesktop_label;
  background-color: var(--primaryColorDark);
  color: var(--matterColorLight);
  padding: 12px 32px;
  font-size: 16px;
  letter-spacing: -0.27px;
  font-weight: var(--fontWeightMedium);
  margin: 18px 0;
  border-radius: 4px;
  @media (--viewportDesktop) {
    font-size: 14px;
  }
}

.createListing:hover {
  background-color: var(--marketplaceColor);
}

.container {
  /* Layout */
  /* width: 100%;
  flex-grow: 1;
  display: flex; */

  max-width: 1440px;
  margin: 0 auto;
  padding: 0 25px;
  width: 100%;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    /* min-height: calc(100vh - var(--topbarHeightDesktop)); */
  }

  @media (--maxViewportSmall) {
    padding-top: 16px;
  }
}
.loaderContainer {
  display: flex;
  padding-top: 150px;
  flex: 1 1;
}
.loaderContainer h3 {
  font-size: 1.5em;
  font-weight: var(--fontWeightMedium);
}
.paddingUnset {
  padding: unset;
}
.container h2 {
  @media (--maxViewportSmall) {
    margin-bottom: 0;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    /* padding: 18px 0 0 0; */
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    /* flex-basis: 62.5%;
    padding: 23px 0 0 0; */
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    /* padding: 0 24px; */
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    /* padding: 0 36px; */
  }
}

.searchFiltersMobile {
  padding: 16px 10px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}
.filter_title {
  padding-right: 10px;
}
.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.noResultListingPanel {
  max-width: 1128px;
  margin: 80px auto 20px;
  padding: 0 36px;
  width: 100%;
}

.noResultListingPanel .heading {
  font-weight: var(--fontWeightLight);
  font-size: 30px;
  margin: 0 0 9px;
  line-height: 48px;
  letter-spacing: -0.9px;
}

.loader_main {
  height: fit-content;
  width: fit-content;
  display: flex;
  color: var(--primaryColorDark);
  justify-content: center;
  align-items: center;
}

.loader_main .loader.threeDots:before,
.loader_main .loader.threeDots:after,
.loader_main .loader.threeDots {
  border-radius: 50%;
  width: 1.5em;
  height: 1.6em;
  left: 20px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load-threeDots 1.5s infinite ease-in-out;
  animation: load-threeDots 1.5s infinite ease-in-out;
}
.loader_main .loader.threeDots {
  margin: auto;
  font-size: 5px;
  position: relative;
  top: -10px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader_main .loader.threeDots:before {
  left: -2.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader_main .loader.threeDots:after {
  left: 2.5em;
}
.loader_main .loader.threeDots:before,
.loader_main .loader.threeDots:after {
  content: '';
  position: absolute;
  top: 0;
}
@-webkit-keyframes load-threeDots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em var(--primaryColorDark);
  }
  40% {
    box-shadow: 0 2.5em 0 0 var(--primaryColorDark);
  }
}
@keyframes load-threeDots {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em var(--primaryColorDark);
  }
  40% {
    box-shadow: 0 2.5em 0 0 var(--primaryColorDark);
  }
}

.joyrideHeading {
  font-size: 16px;
  margin: 0 0 15px 0;
  line-height: 24px;
  font-weight: 500;
}

.joyrideOrderedlist {
  text-align: left;
  margin-bottom: 0;
}

.joyrideOrderedlist li{
  font-size: 15px;
}
